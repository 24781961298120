import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    height: 100vh
  }
  body {
    margin: 0
  }
  a {
    text-decoration: none;
    color: rgb(249, 74, 119);
  }
`

export default GlobalStyle
