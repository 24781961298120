import * as rebass from 'rebass'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

// @ts-ignore @types/styled-system is outdated or incompolete
import {
  display,
  typography,
  color,
  borders,
  position,
  DisplayProps,
  PositionProps,
  MaxWidthProps,
  MinWidthProps
} from 'styled-system'

import GLink from './GLink'

// NOTE rebass.Box doesn't include some types for props for some reason...
// maybe this will get fixed in a later version
export type FlexProps = rebass.FlexProps &
  DisplayProps &
  MaxWidthProps &
  MinWidthProps
export type BoxProps = rebass.BoxProps &
  PositionProps &
  MaxWidthProps &
  MinWidthProps

const Flex: React.FunctionComponent<FlexProps> = styled(rebass.Flex)`
  ${display}
  ${position}
  ${borders}
`

const Box: React.FunctionComponent<BoxProps> = styled(rebass.Box)`
  ${display}
  ${position}
  ${borders}
`

const Separator = styled(Box).attrs({
  borderStyle: 'solid',
  borderWidth: 0,
  borderTop: 0,
  borderColor: 'gray'
})``

const Text = styled(rebass.Text).attrs(({ color }) => ({
  color: color || 'dark',
  fontFamily: 'sans'
}))`
  white-space: pre-line;
`

const Span = styled.span`
  ${color}
`

const Link = styled(rebass.Link).attrs({
  color: 'primary'
})`
  ${typography}
  text-decoration: none;
`

const H1 = styled(Text).attrs({
  fontSize: [3, 4, 4],
  fontFamily: 'sans',
  color: 'black'
})`
  text-transform: uppercase;
`

const H2 = styled(Text).attrs({
  fontSize: 3,
  fontFamily: 'sans',
  color: 'black'
})`
  text-transform: uppercase;
`

const H3 = styled(Text).attrs({
  fontSize: [1, 1, 2],
  color: 'black',
  fontFamily: 'sans'
})`
  text-transform: uppercase;
`

const H4 = styled(Text).attrs({
  fontSize: [1, 1, 2],
  color: 'dark',
  fontFamily: 'sans'
})``

const GBackgroundImage = styled(BackgroundImage)`
  ${display}
`

export * from 'rebass'
export { default as IFrame } from './IFrame'
export { default as Grid } from './Grid'
export {
  Flex,
  Box,
  Separator,
  Text,
  H1,
  H2,
  H3,
  H4,
  GLink,
  Link,
  GBackgroundImage,
  Span
}
export default {
  Flex,
  Box,
  Separator,
  Text,
  H1,
  H2,
  H3,
  H4,
  GLink,
  Link,
  GBackgroundImage,
  Span
}
