import _ from 'lodash'

import theme from './style/theme'

/** Toggle mobile/desktop display based on the value of mobile argument */
export const mobileDisplayToggle = (mobile: boolean, displayValue: string) =>
  mobile ? [displayValue, displayValue, 'none'] : ['none', 'none', displayValue]

/** Get current theme breakpoint based on window size */
export const getMediaBreakpoint = () => {
  const windowEm =
    window.innerWidth /
    parseFloat(getComputedStyle(document.querySelector('html'))['font-size'])

  const breakpoints = _.map(theme.breakpoints, bp =>
    Number(bp.replace('em', ''))
  )

  let index = _.findIndex(breakpoints, bp => windowEm < bp)

  index = index < 0 ? breakpoints.length - 1 : index

  return index
}

