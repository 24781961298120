import React, { useState } from 'react'
import styled from 'styled-components'
import { MdClose } from 'react-icons/md'
import axios from 'axios'
import querystring from 'query-string'
import validator from 'validator'

import { Box, Flex, H2, H3, H4, Text, Button } from '@components/UI'
import {
  height,
  space,
  borders,
  borderWidths,
  borderStyles,
  radii,
  typography
} from 'styled-system'

const Container = styled(Box).attrs({
  p: [2, 3, 3, 3],
  pt: 3,
  backgroundColor: 'white'
})`
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 10000;
`

const Content = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
  // flexDirection: ['column', 'column', 'column', 'row'],
  flexDirection: 'column',
  pb: 3
})``

const TextInput = styled.input.attrs(({ valid }: { valid: boolean }) => ({
  type: 'text',
  mr: [2, 3, 3, 3],
  p: 3,
  my: 'auto',
  mb: [2, 'auto', 'auto', 'auto'],
  height: 3,
  width: ['60px', '140px', '140px', '140px'],
  borderRadius: '4px',
  borderStyle: 'solid',
  borderColor: valid ? 'dark' : 'primary',
  borderWidth: 0,
  fontSize: [1, 2, 2, 2]
}))`
  :focus {
    outline: 0;
  }
  ${height}
  ${space}
  ${borders}
  ${borderWidths}
  ${borderStyles}
  ${radii}
  ${typography}
`

const EmailInput = styled(TextInput).attrs({
  type: 'email'
})``

const Headline = styled(H2).attrs({
  my: 'auto',
  pb: 0,
  color: 'black',
  fontWeight: 700
})``
const Subheadline = styled(Text).attrs({
  my: 'auto',
  pb: 4,
  color: 'black'
  // fontStyle: 'italic'
})``

const SubscribeButton = styled(Button).attrs(
  ({ displayDisabled, disabled }) => ({
    backgroundColor: displayDisabled ? 'dark' : 'primary',
    height: '38px',
    type: 'submit',
    name: 'submit',
    id: 'submit',
    fontSize: [1, 2, 2, 2],
    mr: [5, '0px', '0px', '0px'],
    style: { cursor: disabled ? null : 'pointer' }
  })
)`
  ${typography}
`

const CloseButton = ({
  onClick,
  height,
  mt
}: {
  onClick: Function
  height: string
  mt: string
}) => {
  const Icon = styled(MdClose).attrs({
    fontSize: 24,
    mt: '8px'
  })`
    ${space}
    position: relative;
    margin-left: 18px;
  `

  const Wrap = styled(Box).attrs({
    width: '48px',
    height: height || ['91px', '91px', '91px', '62px']
  })`
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
  `

  return (
    <Wrap onClick={onClick}>
      <Icon />
    </Wrap>
  )
}

const onSubscribe = async ({
  name,
  email
}: {
  name?: string
  email?: string
}) => {
  const { data } = await axios.post(
    'https://sendy.santiandtugce.com/subscribe',
    querystring.stringify({
      // deleted api_key for security reasons
      // api_key: 'DELETED',
      list: 'NL0A9SOndO763msULV9vza9w',
      name,
      email,
      source: 'sendy.santiandtugce.com',
      boolean: true
    }),
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
  )

  switch (data) {
    case 1:
      return 'SUCCESS'
    case 'Already subscribed.':
      return 'ALREADY_SUBSCRIBED'
    default:
      return 'FAILED'
  }
}

const useInput = initialValue => {
  const [value, setValue] = useState(initialValue)

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: event => {
        setValue(event.target.value)
      }
    }
  }
}

const EmailSubscribeForm = () => {
  // SIGNUP, HIDE, SUBSCRIBE_SUCCESS, SUBSCRIBE_FAILED, ALREADY_SUBSCRIBED
  const [barDisplay, setBarDisplay] = useState('SIGNUP')

  const { value: name, bind: bindName, reset: resetName } = useInput('')
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput('')
  const [invalidEmail, setInvalidEmail] = useState('')

  const emailOk = validator.isEmail(email)
  const initState = email.length === 0 && name.length === 0
  const validationOk = emailOk || initState

  const SubscribeSuccess = () => {
    setTimeout(() => setBarDisplay('HIDE'), 5000)

    return (
      <Content height="38px">
        <Headline>Thank you for joining our mailing list!</Headline>
        <CloseButton
          mt="18px"
          height="62px"
          onClick={() => setBarDisplay('HIDE')}
        />
      </Content>
    )
  }

  // Using the same message as with SubscribeSuccess for now
  const AlreadySubscribed = SubscribeSuccess

  const SubscribeFailed = () => {
    setTimeout(() => setBarDisplay('SIGNUP'), 3000)

    return (
      <Content height="38px">
        <Headline>There was a problem... please try again.</Headline>
        <CloseButton
          mt="18px"
          height="62px"
          onClick={() => setBarDisplay('HIDE')}
        />
      </Content>
    )
  }

  const doSubscribe = (e, kind) => {
    if (!emailOk) return
    const isValidKey = e.type === 'keydown' && e.keyCode === 13

    if (isValidKey || kind === 'click') {
      onSubscribe({ name, email }).then(status => {
        if (status === 'SUCCESS') setBarDisplay('SUBSCRIBE_SUCCESS')
        else if (status === 'ALREADY_SUBSCRIBED') {
          setInvalidEmail(email)
          setBarDisplay('ALREADY_SUBSCRIBED')
          resetName()
          resetEmail()
        } else if (status === 'FAILED') setBarDisplay('SUBSCRIBE_FAILED')
      })
    }
  }

  return (
    <Container
      style={{ visibility: barDisplay === 'HIDE' ? 'hidden' : 'visible' }}
    >
      <Content>
        <Headline>Join our Newsletter 💌</Headline>
        <Subheadline>Get an email when we release new music!</Subheadline>
        <Box>
          <form
            action="https://sendy.santiandtugce.com/subscribe"
            method="POST"
            accept-charset="utf-8"
            style={{ display: 'flex' }}
          >
            {/*
            <label htmlFor="name">Name: </label>
            <input type="text" name="name" id="name" />
            */}
            <label htmlFor="email" style={{ marginRight: '8px' }}>
              Email:
            </label>
            <input
              type="email"
              name="email"
              id="email"
              style={{ marginRight: '8px' }}
            />
            <div style={{ display: 'none' }}>
              <label htmlFor="hp">HP</label>
              <br />
              <input type="text" name="hp" id="hp" />
            </div>
            <input type="hidden" name="list" value="NL0A9SOndO763msULV9vza9w" />
            <input type="hidden" name="subform" value="yes" />
            <input type="submit" name="submit" id="submit" value="Subscribe" />
          </form>
        </Box>
        <CloseButton onClick={() => setBarDisplay('HIDE')} />
      </Content>
    </Container>
  )

  /*
  return (
    <Container
      style={{ visibility: barDisplay === 'HIDE' ? 'hidden' : 'visible' }}
    >
      {barDisplay === 'SIGNUP' && (
        <Content>
          <Message>Get an email when we release new music!</Message>
          <Box>
            <TextInput
              onKeyDown={doSubscribe}
              valid
              placeholder="Name"
              {...bindName}
            />
            <EmailInput
              onKeyDown={doSubscribe}
              valid={validationOk}
              placeholder="Email"
              required
              {...bindEmail}
            />
            <SubscribeButton
              disabled={!emailOk}
              displayDisabled={!validationOk}
              onClick={e => doSubscribe(e, 'click')}
            >
              Subscribe
            </SubscribeButton>
            <CloseButton onClick={() => setBarDisplay('HIDE')} />
          </Box>
        </Content>
      )}
      {barDisplay === 'SUBSCRIBE_SUCCESS' && <SubscribeSuccess />}
      {barDisplay === 'ALREADY_SUBSCRIBED' && <AlreadySubscribed />}
      {barDisplay === 'SUBSCRIBE_FAILED' && <SubscribeFailed />}
    </Container>
  )
   */
}

export default EmailSubscribeForm
