import React from 'react'
import { ThemeProvider } from 'styled-components'
import EmailSubscribeForm from '@components/EmailSubscribeForm'

import GlobalStyle from '@style/GlobalStyle'
import theme from '@style/theme'

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <div>
      <GlobalStyle />
      {element}
      <EmailSubscribeForm />
    </div>
  </ThemeProvider>
)
