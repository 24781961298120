import React, { Component } from 'react'
import _ from 'lodash'
import { withTheme } from 'styled-components'
import { Flex, Box } from '@components/UI'

import theme from '@style/theme'
import { getMediaBreakpoint } from '@utils'

/** Transform val1 or val2 such that they match in length. Arrays are
 * matched by filling with last element repeatedly */
const fillMatchArrays = (val1: number | number[], val2: number | number[]) => {
  const array1 = _.isArray(val1) ? val1 : [val1]
  const array2 = _.isArray(val2) ? val2 : [val2]
  const lengthDiff = array1.length - array2.length

  if (lengthDiff > 0) {
    _.times(lengthDiff, () => array2.push(_.last(array2)))
  } else if (lengthDiff < 0) {
    _.times(Math.abs(lengthDiff), () => array1.push(_.last(array1)))
  }

  return [array1, array2]
}

/** Get array of css calc() widths */
const getWidthArray = (
  spacingArray: number[],
  numColsArray: number[],
  spaceArray: number[]
) =>
  _.map(spacingArray, (spaceIndex, index) => {
    const numCols = numColsArray[index]
    const widthPercent = `${100 / numCols}%`
    const space = spaceArray[spaceIndex]
    const spaceInBetween = space * ((numCols - 1) / numCols)

    return `calc(${widthPercent} - ${spaceInBetween}px)`
  })

class Grid extends Component {
  state = { mediaBreakpoint: 0 }

  // STODO how to reuse state.mediaBreakpoint on other components? using high
  // order components?
  updateMediaBreakpoint = _.throttle(
    () => {
      const { mediaBreakpoint } = this.state
      const newBreakpoint = getMediaBreakpoint()

      if (mediaBreakpoint !== newBreakpoint) {
        this.setState({ mediaBreakpoint: newBreakpoint })
      }
    },
    200,
    { leading: false, trailing: true }
  )

  componentDidMount() {
    this.updateMediaBreakpoint()
    window.addEventListener('resize', this.updateMediaBreakpoint)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateMediaBreakpoint)
  }

  render() {
    const {
      numCols = 3,
      spacing = [3],
      elements,
      render,
      ...props
    } = this.props
    const { mediaBreakpoint } = this.state

    const [numColsArray, spacingArray] = fillMatchArrays(numCols, spacing)
    const widthArray = getWidthArray(spacingArray, numColsArray, theme.space)

    // number of columns with current window width
    const currNumCols = numColsArray[mediaBreakpoint]

    return (
      <Flex flexWrap="wrap" {...props}>
        {_.map(elements, (element, index: number) => (
          <Box
            key={index}
            width={widthArray}
            pt={index < currNumCols ? 0 : spacingArray}
            ml={index % currNumCols === 0 ? 0 : spacingArray}
          >
            {render(element)}
          </Box>
        ))}
      </Flex>
    )
  }
}

export default withTheme(Grid)
