export default {
  breakpoints: ['40em', '46em', '64em'],
  borderWidths: [1, 2, 4],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    white: '#FFFFFF',
    light: '#FAFAFA',
    gray: '#eee',
    dark: '#666666',
    black: '#262626',
    // primary: '#FF8345', nice orange
    // primary: '#FF6224', // darker orange
    primary: '#f94a77', // magenta
    secondary: '#E60000',
    success: '#28a745',
    danger: '#dc3545',
    warning: '#ffc107',
    info: '#17a2b8'
  },
  space: [0, 3, 6, 12, 24, 48, 96],
  sizes: [0, 3, 6, 12, 24, 48, 96],
  fonts: {
    sans: 'system-ui, sans-serif',
    mono: 'Menlo, monospace'
  },
  fontWeights: {
    normal: 'normal',
    medium: 500,
    bold: 'bold'
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)'
  }
}

export type Theme = {
  space?: number[]
}
