import React from 'react'
import styled, { withTheme } from 'styled-components'
import { Link as _GLink } from 'gatsby'
// @ts-ignore @types/styled-system is outdated or incompolete
import { typography } from 'styled-system'

import theme from '@style/theme'

const { colors } = theme

/**
 * Gatsby Link component themeable with styled-system
 */
const GLink = props => {
  const {
    color = 'black',
    activeColor = 'black',
    hoverColor = null,
    textDecoration = 'none',
    textTransform
  } = props

  const StyledLink = styled(_GLink)`
    ${typography}
    text-decoration: ${textDecoration};
    text-transform: ${textTransform};
    color: ${colors[color] || color};
    &:hover {
      color: ${colors[hoverColor] || hoverColor};
    }
  `
  return (
    <StyledLink
      {...props}
      activeStyle={{ color: colors[activeColor] || activeColor }}
    />
  )
}

export default GLink
