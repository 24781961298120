// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-release-tsx": () => import("/Users/santi/Development/santi/santiandtugce-ts/src/templates/release.tsx" /* webpackChunkName: "component---src-templates-release-tsx" */),
  "component---src-templates-review-tsx": () => import("/Users/santi/Development/santi/santiandtugce-ts/src/templates/review.tsx" /* webpackChunkName: "component---src-templates-review-tsx" */),
  "component---src-templates-photo-tsx": () => import("/Users/santi/Development/santi/santiandtugce-ts/src/templates/photo.tsx" /* webpackChunkName: "component---src-templates-photo-tsx" */),
  "component---src-pages-404-tsx": () => import("/Users/santi/Development/santi/santiandtugce-ts/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/Users/santi/Development/santi/santiandtugce-ts/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("/Users/santi/Development/santi/santiandtugce-ts/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-events-tsx": () => import("/Users/santi/Development/santi/santiandtugce-ts/src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/santi/Development/santi/santiandtugce-ts/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mixtapes-tsx": () => import("/Users/santi/Development/santi/santiandtugce-ts/src/pages/mixtapes.tsx" /* webpackChunkName: "component---src-pages-mixtapes-tsx" */),
  "component---src-pages-music-tsx": () => import("/Users/santi/Development/santi/santiandtugce-ts/src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-photos-tsx": () => import("/Users/santi/Development/santi/santiandtugce-ts/src/pages/photos.tsx" /* webpackChunkName: "component---src-pages-photos-tsx" */),
  "component---src-pages-press-tsx": () => import("/Users/santi/Development/santi/santiandtugce-ts/src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-test-tsx": () => import("/Users/santi/Development/santi/santiandtugce-ts/src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-videos-tsx": () => import("/Users/santi/Development/santi/santiandtugce-ts/src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */)
}

